<template>
  <div class="container ma-tb-20">
  	<car-state></car-state>
		<div class="bg-fff setting-index">
			<back-button msg="历史任务"></back-button>
			<div>
				<div class="flex f-center ma-t-10 time-box">
					<div class="btn ma-5 ma-r-10 c-666" :class="{'active': isChange == 1}" @click="clickBtn(1)">近3天</div>
					<div class="btn ma-5 ma-r-10 c-666" :class="{'active': isChange == 2}" @click="clickBtn(2)">近7天</div>
					<div class="btn ma-5 ma-r-10 c-666" :class="{'active': isChange == 3}" @click="clickBtn(3)">近30天</div>
					<el-date-picker
						v-model="starTime"
						type="date"
						placeholder="开始时间"
						:disabled-date="disabledDate"
						@change="getTime($event,'start')"
						:clearable="false">
					</el-date-picker>
					<div class="pa-lr-10">~</div>
					<el-date-picker
						v-model="endTime"
						type="date"
						placeholder="结束时间"
						:disabled-date="disabledDate"
						@change="getTime($event,'end')"
						:clearable="false">
					</el-date-picker>
					<div class="btn-search c-fff flex f-center f-jcenter ma-l-10 fon-16" @click="search">查询</div>
				</div>
			</div>
			<div class="list-box flex f-between f-center" v-for="(item,index) in list" :key="index" @click="toPage(item.Id)">
				<div class="list-info-box flex flex-direction f-around">
					<div class="flex f-between fon-16">
						<div class="width50 flex f-center">
							<div class="c-999">任务时间:</div>
							<div class="t-omit-1">{{item.AlarmTime}}</div>
						</div>
						<div class="width50 flex f-center">
							<div class="c-999 ">病人姓名:</div>
							<div class="ma-5 t-omit-1">{{item.PatientName}}</div>
						</div>
					</div>
					<div class="flex f-between fon-16">
						<div class="width50 flex f-center">
							<div class="c-999">现场症状:</div>
							<div class="t-omit-1">{{item.Symptom}}</div>
						</div>
						<div class="width50 flex f-center">
							<div class="c-999">报警人:</div>
							<div class="t-omit-1">{{item.People}}  {{item.Phone}}({{item.PatientRelations}})</div>
						</div>
					</div>
					<div class="flex f-center fon-16">
						<div class="c-999">现场地址:</div>
						<div class="t-omit-1 other">{{item.SceneAddress}}</div>
					</div>
				</div>
				<div class="state-box flex f-center f-jcenter" :class="item.Status == '完成' ? 'bg-8CBD66' : ''">
					{{item.Status}}
				</div>
			</div>
			<van-empty description="暂无历史任务" v-if="list.length == 0"/>
			<div class="flex f-center f-jcenter" v-if="list.length > 0">
				<el-pagination
				  background
					:current-page="pageIndex"
				  layout="prev, pager, next"
				  :total="total"
					@current-change="changePage">
				</el-pagination>
			</div>
		</div>
  </div>
</template>
<script>
let _this;
export default {
  data () {
    return {
			disabledDate(time) {
				return time.getTime() > Date.now()
			},
			isChange: -1, //单选
			starTime:'',
			endTime: '',
			pageIndex: 1,
			list:[],
			total: 0,
			loadDataInterval: null
    }
  },
  created(){
    _this = this;
		_this.loadData();
		let time = localStorage.getItem('refresh_time')
    if (!_this.$validator.isEmpty(time) && time != 0) {
      _this.loadDataInterval = setInterval(() => {
        _this.loadData();
      }, 1000 * time);
    }
  },
	//监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    //next方法传true或者不传为默认历史返回，传false为不执行历史回退
    clearInterval(_this.loadDataInterval);
    next();
  },
  methods:{
		// 选择日期
		clickBtn(index){
			// 单选
			if (index != _this.isChange) {
				_this.isChange = index;
			} else{
				_this.isChange = -1;    //不注释则可以点击取消，注释之后点击就不再取消
			}
			if (_this.isChange == 1) {
				_this.endTime = _this.$util.getDay(0);
				_this.starTime = _this.$util.getDay(-3);
			} else if (_this.isChange == 2) {
				_this.endTime = _this.$util.getDay(0);
				_this.starTime = _this.$util.getDay(-7);
			} else if (_this.isChange == 3) {
				_this.endTime = _this.$util.getDay(0);
				_this.starTime = _this.$util.getDay(-30);
			} else {
				_this.starTime = '';
				_this.endTime = '';
			}
			_this.pageIndex = 1;
			_this.loadData();
		},
		
		// 开始事件
		getTime(e,type){
			if (type == 'start') {
				_this.starTime = _this.$util.changDate(e);
			} else {
				_this.endTime = _this.$util.changDate(e);
			}
			if (_this.$validator.isEmpty(_this.starTime) || _this.$validator.isEmpty(_this.endTime)) {
				_this.isChange = -1
			}
		},
		
		// 切换页码
		changePage(e){
			_this.pageIndex = e;
			_this.loadData();
		},
		
		// 查询
		search(){
			_this.pageIndex = 1;
			_this.loadData();
		},
		
		// 加载数据
		async loadData(){
			let _data = await _this.$request('/Task/v1/TaskTask/QueryPage?startTime=' + _this.starTime + '&endTime=' + _this.endTime + '&pageIndex=' + _this.pageIndex);
			if (_data.Code == 0) {
				_this.list = _data.Data.List;
				_this.total = _data.Data.Total;
			}
		},
		
		// 查看详情
		toPage(id) {
			_this.$router.push({
				name: 'taskHistoryDetail',
				query: {
					id: id
				}
			})
		}
  }
}
</script>

<style lang="less" scoped>
	.title{
		margin-left: 0;
	}
	.setting-index{
		height: auto;
		.btn{
			text-align: center;
			height: 38px;
			width: 70px;
			line-height: 40px;
			border-radius: 4px;
			border: 1px solid #D8D8D8;
			font-size: 18px;
		}
		.active{
			background: #5AB8D9;
			border: 1px solid #5AB8D9;
			color: #FFFFFF;
		}
	}
	
	.list-box{
		width: 100%;
		height: 105px;
		border-radius: 4px;
		border: 1px solid #D7D7D7;
		margin: 10px 0;
		.list-info-box{
			margin-left: 13px;
			height: 90px;
			width: 85%;
			.c-999{
				width: 80px;
			}
			.t-omit-1 {
				width: 300px;
				&.other{
					width: 700px;
				}
			}
		}
		.state-box{
			width: 100px;
			height: 105px;
			background: #5AB8D9;
			border-radius: 4px;
			color: #FFFFFF;
			font-size: 16px;
		}
		.bg-8CBD66{
			background: #8CBD66;
		}
	}
	.btn-search{
		width: 100px;
		height: 40px;
		background: #5CB9DA;
		border-radius: 4px;
	}
</style>